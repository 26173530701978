<template>
  <div
    class="flex items-center gap-6 rounded-[5rem] bg-white py-1.5 pl-1 pr-1.5 shadow-md shadow-gray-200"
  >
    <figure class="size-16 flex-none overflow-clip rounded-full bg-gray-200">
      <NuxtImg
        v-if="agent.content.photo?.filename"
        :src="agent.content.photo.filename"
        :alt="agent.content.photo.alt"
        width="64"
        class="size-full object-cover"
      />
    </figure>

    <div>
      <h3 class="font-medium">
        {{ agent.name }}
      </h3>

      <div class="text-secondary flex items-center gap-1 text-sm">
        <UiIcon icon="mobile-phone" size="sm" />

        <a
          :href="
            isPhoneNumberRevealed ? `tel:${agent.content.phone}` : undefined
          "
          class="cursor-pointer"
          @click="clickToReveal"
        >
          {{ phoneNumber }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok'
import type { ContentTypeAgentStoryblok } from '@/types/storyblok'
import { truncate } from '@/utils/common-utils'

const props = defineProps<{
  agent: ISbStoryData<ContentTypeAgentStoryblok>
}>()
const isPhoneNumberRevealed = ref<boolean>(false)
const phoneNumber = computed(() =>
  isPhoneNumberRevealed.value
    ? props.agent.content.phone
    : truncate(props.agent.content.phone, props.agent.content.phone.length - 3),
)

function clickToReveal($event: Event): void {
  if (isPhoneNumberRevealed.value) {
    return
  }

  $event.preventDefault()

  isPhoneNumberRevealed.value = !isPhoneNumberRevealed.value
}
</script>
