<template>
  <div
    v-if="communityContent"
    v-editable="blok"
    class="container py-[--blockSpacing] lg:py-0"
  >
    <div class="grid grid-cols-12 gap-y-9 lg:gap-x-4">
      <div class="col-span-full lg:col-span-4">
        <h2 class="mb-10 text-2xl font-bold">
          {{ blok.title }}
        </h2>

        <div class="c-content flex flex-col gap-6">
          <div v-if="mappedAgents?.length" class="flex flex-col gap-1.5">
            <AgentCard
              v-for="agent in mappedAgents"
              :key="agent?.uuid"
              :agent="agent"
            />
          </div>

          <div>
            <h3
              :style="{
                '--communityDarkColour':
                  communityContent?.backgroundDark.color ?? colors.primary[500],
              }"
              class="font-bold text-[--communityDarkColour]"
            >
              Sales and Information Centre
            </h3>

            <div v-if="communityContent" class="text-secondary text-sm">
              <DisplaySuiteLocation :community-content="communityContent" />
            </div>
          </div>

          <OpeningHours
            v-if="mappedOpeningHours"
            :data="mappedOpeningHours"
            theme="dark"
          />

          <div
            v-if="
              communityContent && (showBrochureButton || showDirectionsButton)
            "
            class="c-buttons flex flex-row gap-3"
          >
            <UiButton
              v-if="showDirectionsButton && !communityContent.hideVisitUsButton"
              :link="getDirectionsLink(communityContent)"
              target="_blank"
              theme="dark"
              size="sm"
              label="Get Directions"
              variant="outline"
              left-icon="marker"
            />

            <UiButton
              v-if="showBrochureButton"
              :link="mappedBrochure"
              :is-download="true"
              target="_blank"
              theme="dark"
              size="sm"
              label="Download Brochure"
              variant="fill"
              left-icon="download"
            />
          </div>
        </div>
      </div>

      <div class="col-span-full lg:col-span-8 lg:pl-10">
        <StoryblokComponent
          v-for="enquiryForm in blok.enquiryForm"
          :key="enquiryForm._uid"
          :blok="enquiryForm"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <h2 class="text-2xl font-bold">Error!</h2>
    <span> This block can only be on a page under a community. </span>
  </div>
</template>

<script setup lang="ts">
import colors from '#tailwind-config/theme/colors'
import type { ISbStoryData } from 'storyblok'
import { getDirectionsLink } from '@/utils/map-utils'

import type {
  BlockEnquireStoryblok,
  ContentTypeAgentStoryblok,
} from '@/types/storyblok'

defineProps<{ blok: BlockEnquireStoryblok }>()
const { story, communityContent } = storeToRefs(useCurrentStoryStore())

const mappedAgents = computed(() => {
  if (
    isContentTypePropertyStoryblok(story.value) &&
    story.value?.content.agents?.length
  ) {
    return story.value.content.agents.map(
      (agent) => agent as ISbStoryData<ContentTypeAgentStoryblok>,
    )
  }

  return communityContent.value?.agents?.map(
    (agent) => agent as ISbStoryData<ContentTypeAgentStoryblok>,
  )
})
const mappedOpeningHours = computed(() => communityContent.value?.openingHours)
const mappedBrochure = computed(
  () =>
    (isContentTypePropertyStoryblok(story.value) &&
      story.value.content.brochure?.filename) ||
    communityContent.value?.brochure?.filename,
)
const showBrochureButton = computed(
  () => !!communityContent.value?.brochure?.filename,
)
const showDirectionsButton = computed(
  () =>
    !!(
      communityContent.value?.mapLocationLatitude &&
      communityContent.value?.mapLocationLongitude
    ),
)
</script>

<style scoped lang="postcss">
.c-content {
  container-type: inline-size;
}

@container (max-width: 380px) {
  .c-buttons {
    @apply flex-col;

    :deep(.c-button) {
      @apply w-full;
    }
  }
}
</style>
